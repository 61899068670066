import * as React from 'react';
import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import ListItemButton from '@mui/material/ListItemButton';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { useDispatch } from 'react-redux';
import { switchMode } from '../Theme/modeSlice';
import PushNotification from './PushNotification';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import { setUser } from '../Auth/userSlice';
import { setAuthStatus } from '../../request/Auth/authSlice';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { Container } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { setRole } from '../Auth/userSlice';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletButton from '../Wallet/WalletButton';
import { CardActions } from '@mui/material';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

export default function Profile() {
    const user = useSelector((state) => state.user.user);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const appMode = useSelector((state) => state.mode.mode);
    
    const userRole = useSelector((state) => state.user.currentRole);
    const isAuth = useSelector((state) => state.auth.isAuthenticated);  

    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
    const loanBalance = useSelector((state) => state.wallet.loanBalance);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [userImage, setUserImage] = useState(null);

    const { isLoading: isSendingRequest, mutate: uploadProfile } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('image', userImage);
          return await apiClient.post(`/api/profile-update`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            dispatch(setUser(res.data.user));
            setUserImage(null);
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event4 = new Event('processed');
            window.dispatchEvent(event4);  
          },
        }
      );


      const { isLoading: isLoggingOut, mutate: postLogout } = useMutation(
        async () => {
          return await apiClient.post(`/api/logout`);
        },
        {
          onSuccess: (res) => {
              if (res.data.message === 'successful') {
                dispatch(setAuthStatus('unauthenticated'));
                localStorage.removeItem('user');
                localStorage.removeItem('fundings');
                localStorage.setItem('funding-balance', 0);
                localStorage.setItem('subscriptionBalance', 0);
                localStorage.removeItem('subscriptions');
                localStorage.removeItem('notifications');
                localStorage.removeItem('auth-token')
                
                const event = new Event('userIsLoggedOut');
                window.dispatchEvent(event);
                navigate('/login');
    
              }            
          },
          onError: (err) => {
            console.log(err.response?.data || err);
          },
        }
      );


  return (
    <div style={{marginTop: '70px'}}>

    <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={12} md={7}>
            <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                <CardContent>
                    
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <Avatar
                    alt="Remy Sharp"
                    src={userImage === null ? user.profile : URL.createObjectURL(userImage)}
                    sx={{ width: 100, height: 100 }}
                    />
                    <IconButton sx={{marginTop: '-20px', marginBottom: '-20px'}} color="primary" aria-label="upload picture" component="label">
                        <input onChange={(e)=>setUserImage(e.target.files[0])} hidden accept="image/*" type="file" />
                        <PhotoCamera/>
                    </IconButton>
                    {userImage !== null && <Button onClick={uploadProfile} sx={{marginTop: '20px'}} size="small" variant="contained">{isSendingRequest? 'uploading...': 'upload profile'}</Button>}
                    <Typography mt={2} variant="h6" gutterBottom>
                        {user.name}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {user.email}
                    </Typography>
                </Box>
                    
                </CardContent>
            </Card>
        </Grid>     
        
            <Grid item xs={12} md={5}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        subheader={<ListSubheader sx={{zIndex: 0, position: 'relative'}}>ACCOUNT WALLETS</ListSubheader>}
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(fundingBal)} secondary='Account Balance' />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(subBal)} secondary='Cash Back' />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(loanBalance)} secondary='Loans' />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(mainBal)} secondary='Investment Returns' />
                                </ListItemButton>
                            </ListItem>

                            

                            
                        </List>
                    </CardContent>
                    {/* <CardActions sx={{justifyContent: 'space-between'}}>
                        <WalletButton/>
                        <WithdrawalButton/>
                    </CardActions> */}
                </Card>
            </Grid> 

        <Grid item xs={12} md={5}>
            <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                <CardContent>
                    <List
                        sx={{ width: '100%', zIndex: 0, position: 'relative',  maxWidth: 360, bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>Application Settings</ListSubheader>}
                        >
                        <ListItem>
                            <ListItemIcon>
                            <ModeNightIcon />
                            </ListItemIcon>
                            <ListItemText id="app-mode" primary="Dark Mode" />
                            <Switch
                            edge="end"
                            checked={appMode === 'dark'}
                            onChange={()=>{
                                if (appMode === 'dark') {
                                dispatch(switchMode('light'))  
                                }else{
                                    dispatch(switchMode('dark'))
                                }
                            }}
                            inputProps={{
                                'aria-labelledby': 'app-mode',
                            }}
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemIcon>
                            <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText id="logout" primary={isLoggingOut? 'Logging out...': 'Logout'} />
                            <Switch
                            edge="end"
                            checked={!isAuth}
                            onChange={postLogout}
                            inputProps={{
                                'aria-labelledby': 'logout',
                            }}
                            />
                        </ListItem>
                        <PushNotification/>
                    </List>
                </CardContent>
            </Card>
        </Grid> 

        <Grid item xs={12} md={7}>
            <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                <CardContent>
                    <List
                    subheader={<ListSubheader sx={{zIndex: 0, position: 'relative'}}>User Contact</ListSubheader>}
                    >
                        <ListItem disablePadding>
                            <ListItemButton>
                            <ListItemIcon>
                                <MonetizationOnIcon />
                            </ListItemIcon>
                            <ListItemText primary={user.account_number} secondary='Account Number' />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                            <ListItemIcon>
                                <ContactPhoneIcon />
                            </ListItemIcon>
                            <ListItemText primary={user.phone} secondary='Phone Number' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </Grid>  

        {user.roles.length > 0 &&
        <Grid item xs={12} md={5}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                <CardContent>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        subheader={<ListSubheader>Account Roles</ListSubheader>}
                        >
                       {user.roles.map(role =>(
                            <ListItem key={role.id}>
                            <ListItemIcon>
                            <AdminPanelSettingsIcon/>
                            </ListItemIcon>
                            <ListItemText id={role.role} primary={`Switch to ${role.role}`} />
                            <Switch
                            edge="end"
                            checked={userRole === role.role}
                            onChange={() => dispatch(setRole(role.role))}
                            inputProps={{
                                'aria-labelledby': role.role,
                            }}
                            />
                        </ListItem>
                        ))}

                        <ListItem>
                            <ListItemIcon>
                            <AdminPanelSettingsIcon/>
                            </ListItemIcon>
                            <ListItemText id='default' primary={`Switch to Default User`} />
                            <Switch
                            edge="end"
                            checked={userRole === ''}
                            onChange={() => dispatch(setRole(''))}
                            inputProps={{
                                'aria-labelledby': 'default user',
                            }}
                            />
                        </ListItem>
                        
                    </List>
                </CardContent>
            </Card>
        </Grid> 
        }     
    </Grid>
    </div>
  );
}