import React from 'react'
import CoinMarket from './CoinMarket';
import Navigations from './Navigations';
import WalletSlide from '../Wallet/WalletSlide';
import CardsSingle from '../Cards/CardsSingle';
import TransferList from '../Transfers/TransferList';
const Index = () => {
  return (
    <>
    {/* <WalletSlide/> */}
    <CardsSingle/>
    <Navigations/>
    <TransferList/>
    {/* <CoinMarket/> */}
    </>
  )
}

export default Index
