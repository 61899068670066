import React, {Fragment} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';


import WalletButton from '../Wallet/WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TransferList = () => {

  const addressText = (address) => {
    let text = String(address) || address;
    let last6 = text.slice(text.length - 4);
    let first3 = text.substring(0,3);
    return first3+'XXX'+last6;
  }

    const user = useSelector((state) => state.user.user);

    const debits = useSelector((state) => state.history.debits);
    const credits = useSelector((state) => state.history.credits);


    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const allTransferList = debits.concat(credits);

    const combinedTransferList = allTransferList.sort((b, a) => {
        return (a.created_at).localeCompare((b.created_at))
    })

    
  return (
    <div>

{/* <WalletSlide/> */}
      {/* <Card >
        <CardActions sx={{justifyContent: 'space-between'}}>

        <WalletButton/>        

        <WithdrawalButton/>

        </CardActions>
      </Card> */}

<Typography mt={4} px={3} sx={{marginTop: '75px', paddingX: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
        TRANSACTION HISTORY
</Typography>

{combinedTransferList.length > 0 &&
<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

{combinedTransferList.map(transaction => (
   <Fragment key={transaction.id}>
  <ListItem alignItems="flex-start">
    
  <ListItemAvatar>
    
    {parseInt(transaction.user_id) === parseInt(user.id) &&
      <Avatar  sx={{ bgcolor: '#dd0a22' }}>
       <RemoveCircleIcon/>
      </Avatar>
    }
    

    {parseInt(transaction.receiver_id) === parseInt(user.id) &&
    <Avatar  sx={{ bgcolor: '#37dc8a' }}>
      <AddCircleIcon/>
    </Avatar>
     
     }

    
     
  </ListItemAvatar>

  <ListItemText
    primary={transaction?.account_number || transaction.interAccountNumber}
    secondary={
      <React.Fragment>
        <Typography
          component="span"
          variant="caption" display="block"
          color="text.primary"
        >
          {/* {transaction.created_at} */}
          { parseInt(transaction.user_id) === parseInt(user.id)? 'debit' : 'credit' }
        </Typography>
      </React.Fragment>
    }
  />
   

    <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'left', alignItems: 'left'}}>
        <Typography mt={1} edge="end">
        {formatPrice(transaction.amount || transaction.amount)}
        </Typography>
        <Typography color={transaction.status.toLowerCase() === 'approved' ? 'primary.success': 'secondary'}  edge="end">
        {transaction.status}
        </Typography>
    </Box>

  
</ListItem>
<Divider  component="li" variant="inset" />
</Fragment>
))}
</List>
}
    </div>
  )
}

export default TransferList
